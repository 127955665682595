import validate from "/home/forge/deployments/entradas/dev/2024-08-27_14-59-06_e8e1cba/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/pages/runtime/validate.js";
import design_45global from "/home/forge/deployments/entradas/dev/2024-08-27_14-59-06_e8e1cba/layers/theme/middleware/design.global.ts";
import affiliate_45and_45referrer_45global from "/home/forge/deployments/entradas/dev/2024-08-27_14-59-06_e8e1cba/layers/base/middleware/affiliate-and-referrer.global.ts";
import backend_45token_45global from "/home/forge/deployments/entradas/dev/2024-08-27_14-59-06_e8e1cba/layers/base/middleware/backend-token.global.ts";
import consent_45global from "/home/forge/deployments/entradas/dev/2024-08-27_14-59-06_e8e1cba/layers/base/middleware/consent.global.ts";
import manifest_45route_45rule from "/home/forge/deployments/entradas/dev/2024-08-27_14-59-06_e8e1cba/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  design_45global,
  affiliate_45and_45referrer_45global,
  backend_45token_45global,
  consent_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}